/*改善点

・768pxまではPC（タブレットも含む）
・767pxからSP


追加ルール
/*==================
 ▼ブレイクポイントのルール▼
 -スマホを基本設計にする-
 *〜479px：SP縦
 *480px〜：SP横
 *600px〜タブレット
 *960px〜小型PC
 *1280px〜大型PC
==================

*/


//カラー

$mainColor: #00408b;
.mainColor{
	color:$mainColor;
}
.bgMainColor{
    background-color: $mainColor;
}
$mainColorDeep: #002349;
.mainColorDeep{
	color:$mainColorDeep;
}
.bgMainColorDeep{
    background-color: $mainColorDeep;
}

$mainColorExDeep: #03312e;
.mainColorExDeep{
	color:$mainColorExDeep;
}
$mainColorLight: #86a8d0;
.mainColorLight{
	color:$mainColorLight;
}
.bgMainColorLight{
    background-color: $mainColorLight;
}
$mainColorExLight: #edf2ff;
.mainColorExLight{
	color:$mainColorExLight;
}
.bgMainColorExLight{
    background-color: $mainColorExLight;
}

$subColor: #dc5800;
.subColor {
    color: $subColor;
}
.bgSubColor{
    background-color: $subColor;
}

$subColorDeep: #a24700;
.SubColorDeep {
    color: $subColorDeep;
}
.bgSubColorDeep{
    background-color: $subColorDeep;
}

$subColorLight: #FF6F00;
.bgSubColorLight{
    background-color: $subColorLight;
}

$compColor: #d8a800;
.compColor {
    color: $compColor;
}
.bgCompColor{
    background-color: $subColor;
}
$grayColor: #595757;
.grayColor{
    color: $grayColor;
}
.bgGrayColor{
    background-color: $grayColor;
}
$grayColorLight: #8d8e8e;
.bgGrayColorLight{
    background-color: $grayColorLight;
}
$grayColorExLight: #f7f7f7;
.bgGrayColorExLight{
    background-color: $grayColorExLight;
}

$grayColorMstExLight: #f5f5f5;



.bgWhiteTrans {
    background-color: rgba(255, 255, 255, 0.5);
}
.bgBlackTrans {
    background-color: rgba(0, 0, 0, 0.3);
}

.bgBlack{
    background: #000;
}
.bgGrad{
    background: rgb(255,178,90);
    background: linear-gradient(90deg, rgba(255,178,90,1) 0%, rgba(255,143,90,1) 100%);
}
.bgTexture{
    background-image: url(../img/bg_texture_light.gif);
}
.grayLight{
	color:#9a9a9a;
}


/*フォント系*/



$fontEn:'Oswald', sans-serif;
.fontEn {
    font-family: $fontEn;
}

$fontNum: 'Oswald', sans-serif;
.fontNum{
    font-family: $fontNum;
}
.fontEnSub{
    font-family: $fontNum;
}


//ブレイクポイント

@media screen and (min-width:768px){
}
@media screen and (max-width:767px){
}

@media screen and (min-width:768px) and (max-width:959px) {
}



@mixin sp {
  @media (max-width: (767px)) {
    @content;
  }
}
@mixin tab {
  @media (min-width:767px) and (max-width:959px) {
    @content;
  }
}
@mixin spTab {
  @media (max-width:959px) {
    @content;
  }
}

@mixin pc {
  @media (min-width: (768px)) {
    @content;
  }
}
@mixin note {
  @media (min-width: 960px) and (max-width:1279px) {
    @content;
  }
}
@mixin desk {
  @media (min-width: (1281px)) {
    @content;
  }
}



@include sp{}
@include tab{}
@include spTab{}
@include pc{}
@include note{}
@include desk{}
