

/* ==========================================================================
   Author's custom styles
   ========================================================================== */



/*==================================================
 * 共通
 *================================================*/
/*共通部分*/

 

/*==================================================
 * トップページ
 *================================================*/



/*==================================================
 * 下層ページ
 *================================================*/

/*共通部分------------------------------------*/
/*パンくず*/

#pankuzu {
    font-size: 12px;
    color: #727171;
    margin-top: 10px;
    .home &{
        display: none;
    }
}

/*パンくず終わり*/



/*about us------------------------------------*/
